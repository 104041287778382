import { type ParentProps, createContext, onMount, useContext } from "solid-js";
import { createStore } from "solid-js/store";

import { isNativeApp } from "~/utils/isNativeApp";

interface Store {
  state: "play" | "pause" | "buffering";
  volume: number;
  metadata?: NowPlayingInfo | null;
  progress: number;
  allSources: string[];
  currentSourceIndex: number;
}

type SongLyrics = {
  id: string;
  startTime: number;
  endTime: number;
  text: string;
}[];

interface NowPlayingInfo {
  current_time: number;
  current_song_lyrics?: SongLyrics;
  is_live: boolean;
  listeners: ListenersInfo;
  song_history: SongInfo[];
  program: RadioShowInfo;
}

interface ListenersInfo {
  current: number;
  max: number;
}

interface SongInfo {
  album: string;
  artist: string;
  title: string;
  duration?: number;
  start_time: number;
  requested_by?: string;
  cover?: Cover[];
}
export interface RadioShowInfo {
  name: string;
  type: "playlist" | "podcast" | "commercial_break" | "live";
  genre: string;
  description: string;
  djs: DJ[];
  cover: Cover[];
}

interface DJ {
  name: string;
  avatar: Cover[];
}

interface Cover {
  sizes: string;
  src: string;
  type: string;
}

const INITIAL_VOLUME = 1;

const [store, setStore] = createStore<Store>({
  state: "pause",
  volume: INITIAL_VOLUME,
  metadata: null,
  progress: -1,
  allSources: [],
  currentSourceIndex: 0,
});

const Context = createContext(store);

export function onPlayerLoad(callback: () => void) {
  if (isNativeApp()) {
    return;
  }

  if (window.jhplayer) {
    callback();
    return;
  }

  document.addEventListener("jhplayerinit", callback, { once: true });
}

export function usePlayer() {
  return useContext(Context);
}

export default function PlayerContext(props: ParentProps) {
  onMount(() => {
    onPlayerLoad(() => {
      window.jhplayer.volume = INITIAL_VOLUME;

      window.jhplayer.on({
        "play pause buffering"(event: any) {
          setStore("state", event.type);
        },
        volumechange() {
          setStore("volume", window.jhplayer.volume);
        },
        gotmetadata(event: any) {
          setStore("metadata", event.detail);
        },
        metadataerror() {
          setStore("metadata", null);
        },
        songprogress(event: any) {
          setStore("progress", event.detail);
        },
      });

      setStore("allSources", window.jhplayer.allSources);
      setStore("currentSourceIndex", window.jhplayer.currentSourceIndex);

      try {
        setStore("metadata", window.jhplayer.metadata);
      } catch {
        //
      }
    });
  });

  return <Context.Provider value={store}>{props.children}</Context.Provider>;
}
